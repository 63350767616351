import * as React from 'react';
import { useLocaleString } from '@devsisters/gatsby-plugin-i18n';

import { Helmet } from 'react-helmet';

const HomePage: React.FC = () => {
  const locale = useLocaleString();
  const redirectURL = locale === 'ko' ?
    'https://devsisters.notion.site/5ba3adfb76a94731853fae55b5a5b44c'
    : 'https://devsisters.notion.site/fb5915b846de408fb0816bbe7ecfae2a';
  React.useEffect(() => {
    window.location.href = redirectURL;
  });
  return (
    <Helmet>
      <meta property="og:url" content={redirectURL} />
      <link rel="canonical" href={redirectURL} />
    </Helmet>
  );
};

export default HomePage;
